import React, { useMemo } from 'react';
import {
  Worship24,
  WatsonHealthHangingProtocol24,
  Transgender24,
  WatsonHealthMagnify24,
  Education24,
  Time24,
} from '@carbon/icons-react';

export const FEATURES = {
  UNIFORMS: 'Uniforms',
  LANGUAGE: 'Language Education',
  LANGUAGE_EDUCATION: 'Additional language classes',
  GENDER: 'Gender',
  GENDER_LABEL: 'Coed',
  ACCESSIBILITY_PROGRAMS: 'Accessibility Programs',
  RELIGION: 'Religious Affiliation',
  CORE_KNOWLEDGE: 'Core Knowledge',
  CORE_KNOWLEDGE_LABEL: 'School Focus',
  SCHEDULE: 'Schedule',
  EDUCATIONAL: 'Specific Educational Approach',
  TRADITIONAL: 'Traditional',
  ALTERNATIVE: 'Alternative',
  EDUCATIONAL_LABEL: 'Educational Approach',
  SCHOLARSCHIP_PROGRAMS: 'Scholarship Programs',
  SCHOLARSCHIP_PROGRAMS_LABEL: 'Payment Resources',
  SCHOOL_ACCREDITATIONS: 'School Accreditations',
  GIFTED_STUDENTS_SUPPORT: 'Gifted Students support',
  LANGUAGE_EDUCATION: 'Language Education',
};

export const icons = {
  [FEATURES.CORE_KNOWLEDGE_LABEL]: <WatsonHealthMagnify24 />,
  [FEATURES.EDUCATIONAL_LABEL]: <Education24 />,
  [FEATURES.SCHOLARSCHIP_PROGRAMS_LABEL]: <Education24 />,
  [FEATURES.SCHOOL_ACCREDITATIONS]: <Education24 />,
  [FEATURES.UNIFORMS]: <WatsonHealthHangingProtocol24 />,
  [FEATURES.GENDER_LABEL]: <Transgender24 />,
  [FEATURES.RELIGION]: <Worship24 />,
  [FEATURES.ACCESSIBILITY_PROGRAMS]: <WatsonHealthMagnify24 />,
  [FEATURES.SCHEDULE]: <Time24 />,
};

export const getInsightsFromFeatures = (features) => {
  const generalFeatureData = {};
  const scholarshipFeatureData = {};
  const teachingMethod = {};
  const tuitionFeatures = {};

  features?.forEach((feature) => {
    const groupName = feature.group.name;

    switch (groupName) {
      case FEATURES.GENDER:
        if (!generalFeatureData[FEATURES.GENDER_LABEL]) {
          generalFeatureData[FEATURES.GENDER_LABEL] = [];
        }
        generalFeatureData[FEATURES.GENDER_LABEL] && generalFeatureData[FEATURES.GENDER_LABEL].push(feature.name);

        break;

      case FEATURES.RELIGION:
        if (!generalFeatureData[FEATURES.RELIGION]) {
          generalFeatureData[FEATURES.RELIGION] = [];
        }
        generalFeatureData[FEATURES.RELIGION] && generalFeatureData[FEATURES.RELIGION].push(feature.name);

        break;

      case FEATURES.SCHEDULE:
        if (!generalFeatureData[FEATURES.SCHEDULE]) {
          generalFeatureData[FEATURES.SCHEDULE] = [];
        }
        generalFeatureData[FEATURES.SCHEDULE] && generalFeatureData[FEATURES.SCHEDULE].push(feature.name);

        break;

      case FEATURES.UNIFORMS:
        generalFeatureData[FEATURES.UNIFORMS] = 'Yes';

        break;

      // tuition
      case FEATURES.SCHOLARSCHIP_PROGRAMS:
        if (!tuitionFeatures[FEATURES.SCHOLARSCHIP_PROGRAMS_LABEL]) {
          tuitionFeatures[FEATURES.SCHOLARSCHIP_PROGRAMS_LABEL] = [];
        }
        tuitionFeatures[FEATURES.SCHOLARSCHIP_PROGRAMS_LABEL].push(feature.name);

        break;

      case FEATURES.SCHOOL_ACCREDITATIONS:
        if (!generalFeatureData[FEATURES.SCHOOL_ACCREDITATIONS]) {
          generalFeatureData[FEATURES.SCHOOL_ACCREDITATIONS] = [];
        }
        generalFeatureData[FEATURES.SCHOOL_ACCREDITATIONS].push(feature.name);

        break;

      // School Programs
      case FEATURES.GIFTED_STUDENTS_SUPPORT:
        if (!scholarshipFeatureData[FEATURES.GIFTED_STUDENTS_SUPPORT]) {
          scholarshipFeatureData[FEATURES.GIFTED_STUDENTS_SUPPORT] = [];
        }
        scholarshipFeatureData[FEATURES.GIFTED_STUDENTS_SUPPORT].push(feature.name);

        break;

      case FEATURES.LANGUAGE_EDUCATION:
        if (!scholarshipFeatureData[FEATURES.LANGUAGE_EDUCATION]) {
          scholarshipFeatureData[FEATURES.LANGUAGE_EDUCATION] = [];
        }
        scholarshipFeatureData[FEATURES.LANGUAGE_EDUCATION].push(feature.name);

        break;

      // TeachingMethod
      case FEATURES.EDUCATIONAL:
      case FEATURES.ALTERNATIVE:
      case FEATURES.TRADITIONAL:
        if (!teachingMethod[FEATURES.EDUCATIONAL_LABEL]) {
          teachingMethod[FEATURES.EDUCATIONAL_LABEL] = [];
        }
        teachingMethod[FEATURES.EDUCATIONAL_LABEL] && teachingMethod[FEATURES.EDUCATIONAL_LABEL].push(feature.name);

        break;

      case FEATURES.CORE_KNOWLEDGE:
        if (!teachingMethod[FEATURES.CORE_KNOWLEDGE_LABEL]) {
          teachingMethod[FEATURES.CORE_KNOWLEDGE_LABEL] = [];
        }
        teachingMethod[FEATURES.CORE_KNOWLEDGE_LABEL] &&
          teachingMethod[FEATURES.CORE_KNOWLEDGE_LABEL].push(feature.name);

        break;

      default:
        break;
    }
  });

  return {
    generalFeatureData: Object.entries(generalFeatureData),
    scholarshipFeatureData: Object.entries(scholarshipFeatureData),
    teachingMethod: Object.entries(teachingMethod),
    tuitionFeatures: Object.entries(tuitionFeatures),
  };
};

export const getStudentsPerTeachersRatio = ({ numberOfStudents, numberOfTeachers }) => {
  if (numberOfStudents && numberOfTeachers) {
    let aux;
    const w = numberOfTeachers;
    const h = numberOfStudents;

    const r = w === 0 ? 1 : w;
    aux = `${parseInt(h / r, 10)} : ${w / r}`;
    return aux;
  }
};

export const isTuition = (tuition) => {
  if (!tuition || tuition === 'More Information') return null;

  return tuition;
};

export const useOfferTransportation = ({ hasTransportation, features }) => {
  const offerTransport = useMemo(
    () => hasTransportation || features.find((item) => item.name === 'Transportation Offered'),
    [hasTransportation]
  );

  return offerTransport ? 'Offer Transportation' : 'No Offer Transportation';
};
