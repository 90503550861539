import React, { useMemo } from 'react';
import styles from './see-more.module.scss';
import { tString } from 'components/translation/Translation';
import HtmlParser from 'html-react-parser';
import { _getLocation } from 'controllers/location/location_controller';
import { isArray } from 'lodash';

export const SeeMoreLess = ({ content, limit, handleSeeMoreClick }) => {
  let count = 0;
  let displayContent = [];
  const visibleText = (text, html) => {
    const arr = isArray(html)
      ? html
      : html.props && html.props.children && isArray(html.props.children)
      ? html.props.children
      : undefined;
    if (arr) {
      arr.forEach((element) => {
        if (element.props) {
          if (element.props.children) {
            if (isArray(element.props.children)) {
              text += visibleText('', element.props.children);
            } else if (element.props.children.props && element.props.children.props.children) {
              text += element.props.children.props.children;
            } else if (element.props.children && !element.props.children.type) {
              text += element.props.children;
            }
          } else {
            text += element;
          }
        } else {
          text += element;
        }
      });
    } else if (html.props && html.props.children) {
      text += html.props.children;
    } else if (!html.props) {
      text += html.replace(/\r?\n/g, '<br />');
    }
    return text;
  };

  const reduceText = (html) => {
    let newContent;
    let text = '';
    const arr = isArray(html)
      ? html
      : html.props && html.props.children && isArray(html.props.children)
      ? html.props.children
      : undefined;
    if (arr) {
      newContent = arr.forEach((element) => {
        if (count < limit) {
          if (element.props && element.props.children) {
            // console.log(count, element);
            if (isArray(element.props.children)) {
              // const _newContent = reduceText(element.props.children);
              // displayContent.push(_newContent);
              reduceText(element.props.children);
            } else if (element.props.children.props && element.props.children.props.children) {
              text += element.props.children.props.children;
              count += text.length;
              displayContent.push(element);
              // return element;
            } else if (element.props.children && !element.props.children.type) {
              text += element.props.children;
              count += text.length;
              // console.log(element);
              displayContent.push(element);
              // return element;
            } else if (
              element.props.children &&
              (element.props.children.type === 'strong' ||
                element.props.children.type === 'p' ||
                element.props.children.type === 'br')
            ) {
              displayContent.push(element);
            }
          } else if (!element.props) {
            displayContent.push(element);
          }
        }
      });
    } else if (html.props && html.props.children) {
      text += html.props.children;
      displayContent.push(html);
    } else if (!html.props) {
      displayContent = html.replace(/\r?\n/g, '<br />'); // .substring(0, limit);
    }
    return newContent;
  };

  const options = {
    replace(domNode) {
      if (domNode.type === 'text') {
        domNode.data = domNode.data.replace(/\r?\n/g, '<br />'); // .substring(0, limit);
      }
    },
  };

  const fullContentString = content.replace(/\r?\n/g, '<br />');
  const fullContent = HtmlParser(fullContentString);
  reduceText(HtmlParser(fullContentString, options));
  const fullContentLenght = visibleText('', fullContent).length;
  const displayContentLenght = visibleText('', displayContent).length;
  // console.log(limit, fullContentLenght, displayContentLenght);

  // const handleSeeMoreClick = () => {
  //   const aboutElement = document.getElementById('about');
  //   if (aboutElement) {
  //     const offset = aboutElement.offsetTop - 64;
  //     window.scroll({
  //       top: offset,
  //       behavior: 'smooth',
  //     });
  //   }
  // };

  const isDescriptionGreater = useMemo(() => fullContentLenght > limit, [content]);

  return (
    <>
      <span className={`${styles.content} ${styles.resetStyles}`}>
        {isDescriptionGreater ? displayContent : fullContent}
        {isDescriptionGreater ? '... ' : ' '}
        {isDescriptionGreater && handleSeeMoreClick && (
          <a className={styles.seeMore} onClick={handleSeeMoreClick} href="#school-about">
            {tString('schoolProfile:seeMore')}
          </a>
        )}
      </span>
    </>
  );
};
